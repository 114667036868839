<template>
  <v-dialog v-model="dialog" width="fit-content" persistent class="dialog">
    <v-container class="grey lighten">
      <v-row boolean>
        <v-col cols="12" sm="5" class="col1">
          <v-img
            src="../assets/special-offer/image.png"
            style="width:24rem"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="7" class="pa-0 col2">
          <v-card
            class="pa-2"
            outlined
            tile
            style="position:relative; height: inherit;"
          >
            <v-icon
              medium
              color="black darken-2"
              class="icon"
              @click="$emit('closeOfferForm')"
            >
              mdi-close
            </v-icon>
            <form @submit.prevent="submit">
              <div class="form-group">
                <label for="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  minLength="6"
                  required
                  v-model="name"
                />
              </div>
              <div class="form-group">
                <label for="email">
                  Email
                </label>
                <input type="email" id="email" v-model="email" required />
              </div>
              <div class="form-group">
                <label for="phone">
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  required
                  min="1"
                  @keypress="IsNumber($event)"
                  v-model="phoneNumber"
                />
              </div>
              <div class="form-group">
                <label for="company">
                  Company Website
                </label>
                <input type="text" id="company" v-model="company" required />
              </div>
              <div class="form-group">
                <label for="message">
                  Message
                </label>
                <textarea id="company" v-model="message"  rows="4" />
              </div>

              <div class="d-flex justify-end">
                <v-btn class="btn" type="submit" :loading="loading"
                  >Submit</v-btn
                >
                <!-- <button class="btn" type="submit">Submit</button> -->
              </div>
            </form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "OfferForm",
  data() {
    return {
      //   dialog: false,
      phoneNumber: "",
      isValidPhoneNumber: true,
      name: "",
      email: "",
      company: "",
      message:'',
      loading: false,
    };
  },
  props: {
    dialog: Boolean,
  },
  methods: {
    validatePhoneNumber() {
      const validationRegex = /^\d{10}$/;
      if (this.phoneNumber.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    submit() {
      this.loading = true;
      const payload = {
        name: this.name,
        email: this.email,
        phone: this.phoneNumber,
        companyWebsite: this.company,
        message: this.message,
      };

      axios
        .post(
          "https://stage-gricd.herokuapp.com/v2/admin/salesConnect",
          payload
        )
        .then((response) => {
          this.$toast.success(response.data.message);
          this.loading = false;
          this.$emit("closeOfferForm");
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    IsNumber(event) {
      if (!/^[0-9]\d*(\.\d+)?$/.test(event.key)) return event.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.column1 {
  width: 20rem;
  height: 32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #28a84a;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
input,textarea {
  background: #f7f8f9;
  border: 0.5px solid #b7c3cd;
  border-radius: 8px;
  padding: 10px;
}

input{
  height: 46px;
}
input:focus,textarea:focus {
  outline: none;
}
label {
  font-weight: 400;
  font-size: 13px;
  line-height: 140.62%;
  color: #404a57;
  margin-bottom: 0.4rem;
}
.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  color: #606060;
}
.col1 {
  //   height: 35rem;
  background-color: #28a84a;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 10px;
}
.btn {
  background: #28a84a !important;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
  //   height: 30px;
}

form {
  //   padding: 20px 40px;
  padding: 40px 45px 15px;
  height: inherit;
  display: flex;
  flex-direction: column;
  place-content: center;
}

.dialog {
  border-radius: 16px;
}
</style>
