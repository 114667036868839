<template>
  <v-container fluid>
    <v-row class="background">
      <v-col cols="12" sm="2" lg="2" class="align-center">
        <v-img :src="logo" alt="logo" class="logo" />
        <p class="text-body-2 white--text mt-8">
          Real-time cold chain monitoring that power intelligent decisions.
        </p>
      </v-col>
      <v-col
        cols="6"
        sm="2"
        lg="2"
        :class="$vuetify.breakpoint.xs ? '' : 'pl-10'"
      >
        <p class="h4 font-weight-bold white--text">
          Company
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/about">About us</router-link>
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/about/case-studies">
            Case Studies
          </router-link>
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/cold-chain-equipments">
            ColdChain Equipment
          </router-link>
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/blog">
            Blog
          </router-link>
        </p>
      </v-col>
      <v-col cols="6" sm="1" lg="1" :class="''">
        <p class="h4 font-weight-bold white--text">
          Applications
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/cold-chain-application/healthcare">
            Healthcare
          </router-link>
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/cold-chain-application/logistics">
            Logistics
          </router-link>
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/cold-chain-application/agriculture">
            Agriculture
          </router-link>
        </p>
      </v-col>
      <v-col
        cols="6"
        sm="2"
        lg="2"
        :class="$vuetify.breakpoint.xs ? '' : 'pl-16'"
      >
        <p class="h4 font-weight-bold white--text">
          Get in Touch
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/contact/contact-us">
            Contact us
          </router-link>
        </p>
        <p
          class="text-body-2 white--text"
          style="cursor:pointer"
          @click="support()"
        >
          Support
        </p>
      </v-col>
      <v-col
        cols="6"
        sm="2"
        lg="2"
        :class="$vuetify.breakpoint.xs ? '' : 'pl-10'"
      >
        <p class="h4 font-weight-bold white--text">
          Legal
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/terms&conditions">
            Terms & Conditions
          </router-link>
        </p>
        <p class="text-body-2 white--text">
          <router-link to="/privacy">
            Privacy
          </router-link>
        </p>
      </v-col>
      <v-col class="news-letter" cols="12" sm="3" lg="3">
        <p class="h4 font-weight-bold white--text">
          Newsletters
        </p>
        <p class="text-body-2 white--text">
          Stay informed about upcoming events, new products and special
          announcements.
        </p>
        <div class="flex-container">
          <v-text-field
            dense
            placeholder="Kindly enter your email"
            outlined
            large
            text-field-border-radius="0"
            class="d-inline rounded-0"
            background-color="white"
            style="font-size: 0.73rem;"
          ></v-text-field>

          <v-btn
            height="40px"
            color="#28A84A"
            tile
            class="d-inline white--text"
            style="    font-size: 0.7rem;
    font-weight: 600;"
            >Subscribe</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <!-- <hr /> -->
    <v-row
      class="background btm-foot pb-4"
      style="border-top: 0.5px solid #CACACA"
    >
      <v-col class="justify-space-between" cols="4" sm="7">
        <p class="text-body-2 white--text">Copyright ©{{ year }} Gricd</p>
      </v-col>
      <v-col cols="8" sm="5" class="logos">
        <v-icon
          medium
          color="white darken-2"
          class="btm-logo"
          @click="goFb()"
          style="cursor:pointer"
        >
          mdi-facebook
        </v-icon>
        <v-icon
          medium
          color="white darken-2"
          class="btm-logo"
          style="cursor:pointer"
          @click="goYT()"
        >
          mdi-youtube
        </v-icon>
        <v-icon
          medium
          color="white darken-2"
          class="btm-logo"
          @click="goTwit()"
          style="cursor:pointer"
        >
          mdi-twitter
        </v-icon>
        <v-icon
          medium
          color="white darken-2"
          class="btm-logo"
          style="cursor:pointer"
          @click="goLinkedIn()"
        >
          mdi-linkedin
        </v-icon>
        <v-icon
          medium
          color="white darken-2"
          @click="goIG()"
          class="btm-logo"
          style="cursor:pointer"
        >
          mdi-instagram
        </v-icon>
        <!-- <img :src="yt" alt="logo" class="btm-logo" />
        <img :src="lkdin" alt="logo" class="btm-logo" />
        <img
          :src="twitter"
          alt="logo"
          class="btm-logo"
          @click="goTwit()"
          style="cursor:pointer"
        />
        <img
          :src="IG"
          alt="logo"
          class="btm-logo"
          @click="goIG()"
          style="cursor:pointer"
        /> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo from '../assets/SVG/logo 1.svg';
import sm from '../assets/Group 69.png';
import yt from '../assets/icons/Youtube.png';
import fb from '../assets/icons/Facebook.png';
import lkdin from '../assets/icons/linkedin.png';
import twitter from '../assets/icons/twitter.png';
import IG from '../assets/icons/instagram.png';

export default {
  name: 'Footer',
  data: () => ({logo, sm, yt, IG, twitter, fb, lkdin}),
  methods: {
    goFb: () => {
      window.open('https://www.facebook.com/gricd/', '_blank').focus();
    },
    goIG: () => {
      window.open('https://www.instagram.com/thegricd/', '_blank').focus();
    },
    goTwit: () => {
      window.open('https://twitter.com/TheGricd', '_blank').focus();
    },
    goLinkedIn: () => {
      window
        .open('https://www.linkedin.com/company/gricd/mycompany/', '_blank')
        .focus();
    },
    goYT: () => {
      window
        .open('https://www.youtube.com/results?search_query=gricd', '_blank')
        .focus();
    },
    support: () => {
      window.open('https://support.gricd.com/', '_blank').focus();
    },
  },
  computed: {
    year() {
      let year;
      year = new Date().getFullYear();
      return year;
    },
  },

  // metaInfo: {
  //   script: [
  //     {
  //       type: 'application/ld+json',
  //       json: {
  //         '@context': 'http://schema.org',
  //         '@type': 'Structured data',
  //       },
  //     },
  //   ],
  // },
};
</script>

<style scoped lang="scss">
.background {
  padding: 2rem 4rem 0rem;
  background: #1e1e2d;
  @media (max-width: 769px) {
    padding: 1rem 0.5rem 0rem !important;
  }
  @media (max-width: 1367px) {
    padding: 4rem 2rem 0rem;
  }
}

.btm-foot {
  @media screen and (max-width: 1367px) {
    padding: 10px 2rem;
  }
}

p {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 160% !important;
  color: #ffffff;
}
.flex-container {
  display: flex;
}
button {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}
.logo {
  position: relative;
  right: 11%;
  width: 10rem;
  @media (max-width: 769px) {
    right: 8%;
  }
}
.btm-logo {
  padding-left: 1rem;
  @media (max-width: 769px) {
    padding-left: 0;
    width: 35px;
    height: 35px;
  }
}
// .news-letter {
//   padding-right: 5rem;
//   padding-left: 5rem;
// }

@media screen and (max-width: 769px) {
  .news-letter {
    // padding-left: initial !important;
    // padding-right: initial !important;
  }
}

@media screen and (max-width: 1367px) {
  .news-letter {
    // padding-left: initial;
    // padding-right: initial;
  }
}
p a {
  text-decoration: none;
  color: white;
}
.logos {
  justify-content: flex-end;
  display: flex;
  padding-right: 8rem;
  @media screen and (max-width: 769px) {
    padding-right: inherit;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1367px) and (min-width: 770px) {
    padding-right: 6.2rem;
  }
}
.text-body-2 {
  font-size: 0.875rem !important;
}
.theme--light.v-icon:focus::after {
  opacity: 0;
}
</style>
