<template>
  <div style="z-index:100">
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="position:fixed; top:0; left:0; overflow-y:scroll;"
    >
      <div class="mobile">
        <img
          class="mobile-logo"
          :src="logo"
          alt="logo"
          @click="redirectToHome()"
          id="logo-img"
        />
      </div>

      <v-divider></v-divider>
      <v-list>
        <v-list-item to="/home" link style="border-bottom: solid 1px #f1f1f1;">
          <v-list-item-icon>
            <!-- <v-icon v-text="'mdi-home'"></v-icon> -->
            <v-icon class="material-icons">
              home
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'Home'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-for="(item, i) in items"
          :key="i + '8'"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          active-class=""
          style="border-bottom: solid 1px #f1f1f1;"
        >
          <template v-slot:activator>
            <!-- <v-icon class="material-icons">
              {{item.action}}
            </v-icon> -->
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(child, index) in item.items"
            :key="index + '1'"
            :to="child.link"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          to="/cold-chain-equipments"
          link
          two-line
          style="border-bottom: solid 1px #f1f1f1;"
        >
          <v-list-item-icon>
            <v-icon class="material-icons">
              construction
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="'ColdChain Equipments'"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/blog" link style="border-bottom: solid 1px #f1f1f1;">
          <v-list-item-icon>
            <v-icon class="material-icons">
              auto_stories
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'Blog'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item
          to="contact/contact-us"
          link
          style="border-bottom: solid 1px #f1f1f1;"
        >
          <v-list-item-icon>
            <v-icon v-text="'mdi-contacts'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'Contact'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-group
          v-for="(item, i) in itemContact"
          :key="i + '2'"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          active-class=""
          style="border-bottom: solid 1px #f1f1f1;"
        >
          <template v-slot:activator>
            <!-- <span class="material-icons icon-class">
              contacts
            </span> -->
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(child, index) in item.items"
            :key="index + '3'"
            :to="child.link"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <div class="mt-10 px-4">
        <v-btn style="font-size:12px" width="100%" @click="goLogin()"
          >Login</v-btn
        >
      </div>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="white px-5"
      height="70px"
      flat
      style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07) !important;"
    >
      <v-app-bar-nav-icon @click="drawer = true" v-if="$vuetify.breakpoint.xs">
        <span class="material-icons">
          menu
        </span>
      </v-app-bar-nav-icon>

      <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
      <!-- <v-toolbar-title> -->
      <!-- <template> -->
      <!-- <router-link to'/'> -->
      <!-- <router-link to="/"> -->
      <img
        class=""
        :src="logo"
        alt="logo"
        style="width:5rem;cursor:pointer"
        @click="redirectToHome()"
        id="logo-img"
      />
      <!-- </router-link> -->
      <!-- </router-link> -->
      <!-- </template> -->
      <!-- </v-toolbar-title> -->

      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <div class="d-none d-sm-flex" v-if="$vuetify.breakpoint.smAndUp">
        <h4 :class="$route.name === 'Home' ? 'set-active' : ''">
          <router-link to="/home">Home</router-link>
        </h4>
        <v-menu
          offset-y
          v-for="(path, i) in links"
          :key="i + '4'"
          open-on-hover
          open-delay="200"
          close-delay="300"
        >
          <template v-slot:activator="{on, attrs}">
            <h4 v-bind="attrs" v-on="on">
              <router-link :to="path.links">{{ path.name }}</router-link>
            </h4>
          </template>
          <!-- <v-hover v-slot="{hover}"> -->
          <v-list v-if="path.children.menu">
            <v-list-item
              v-for="(child, index) in path.children.link"
              :key="index + '5'"
              :to="child.first + child.link"
            >
              <v-list-item-title>
                <h4>
                  {{ child.name }}
                </h4>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <!-- </v-hover> -->
        </v-menu>

        <v-menu
          offset-y
          v-for="(path, i) in contact"
          :key="i + '6'"
          open-on-hover
          open-delay="200"
          close-delay="300"
        >
          <template v-slot:activator="{on, attrs}">
            <h4 v-bind="attrs" v-on="on" class="last">
              <router-link :to="path.links">{{ path.name }}</router-link>
            </h4>
          </template>

          <v-list>
            <v-list-item
              v-for="(child, index) in path.children.link"
              :key="index + '7'"
              :to="child.first + child.link"
            >
              <v-list-item-title>
                <h4>
                  {{ child.name }}
                </h4>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn style="font-size:12px" @click="goLogin()">Login</v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import logo from '../assets/Logo/logo.png';

export default {
  name: 'Navbar',
  data: () => ({
    drawer: false,
    group: null,
    show: false,
    links: [
      // {name: 'Home', links: '/home', children: {menu: false}},
      {
        name: 'About us',
        links: '/about',
        children: {
          menu: true,
          link: [
            {name: 'Our Story', link: '/our-story', first: '/about'},
            {name: 'Case Study', link: '/case-studies', first: '/about'},
          ],
        },
      },
      {
        name: 'Application',
        links: '/cold-chain-application',
        children: {
          menu: true,
          link: [
            {
              name: 'Healthcare',
              link: '/healthcare',
              first: '/cold-chain-application',
            },
            {
              name: 'Logistics',
              link: '/logistics',
              first: '/cold-chain-application',
            },
            {
              name: 'Agriculture',
              link: '/agriculture',
              first: '/cold-chain-application',
            },
          ],
        },
      },
      {
        name: 'ColdChain Equipment',
        links: '/cold-chain-equipments',
        children: {menu: false},
      },
      {name: 'Blog', links: '/blog', children: {menu: false}},
      // {name: 'Contact', links: '/a', children: {menu: false}},
    ],
    contact: [
      {
        name: 'Contact',
        links: '/contact',
        children: {
          menu: true,
          link: [
            {name: 'Contact us', link: '/contact-us', first: '/contact'},
            {
              name: 'Request a Demo',
              link: '/demo-request',
              first: '/contact',
            },
          ],
        },
      },
    ],
    logo,
    items: [
      {
        action: 'info',
        items: [
          {title: 'Our Story', link: '/about/our-story'},
          {title: 'Case Studies', link: '/about/case-studies'},
        ],
        title: 'About US',
        link: '/about',
      },
      {
        action: 'settings',
        active: false,
        items: [
          {title: 'Healthcare', link: '/cold-chain-application/healthcare'},
          {title: 'Logistics', link: '/cold-chain-application/logistics'},
          {title: 'Agriculture', link: '/cold-chain-application/agriculture'},
        ],
        title: 'Applications',
      },
    ],
    itemContact: [
      {
        action: 'contacts',
        items: [
          {title: 'Contact Us', link: '/contact/contact-us'},
          {title: 'Request a Demo', link: '/contact/demo-request'},
        ],
        title: 'Contact',
        link: '/contact',
      },
    ],
  }),
  methods: {
    goLogin() {
      location.href = 'https://enterprise.gricd.com/login';
    },
    redirectToHome() {
      this.$router.push({path: '/'});
    },
  },
  mounted() {},
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: [
            {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'https://gricd.com/',
              logo: 'https://www.gricd.com/img/logo%201.76d35fe6.svg',
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  telephone: '+234 703 667 7576',
                  contactType: 'sales',
                  availableLanguage: ['English'],
                  description: 'Mobile Phone Number',
                },
              ],
              sameAs: [
                'https://web.facebook.com/gricd',
                'https://twitter.com/TheGricd',
                'https://www.instagram.com/thegricd/',
                'https://www.linkedin.com/company/gricd/mycompany/',
              ],
            },
            {
              '@context': 'http://schema.org',
              '@type': 'WebSite',
              name: 'Gricd',
              url: 'https://gricd.com/',
            },
          ],
        },
      ],

      meta: [
        {
          name: 'description',
          content:
            'Find the best IoT cold chain equipments on gricd.com to transport, secure, store and monitor temparature sensitive products in real time ranging from agricultural products to healthcare products including vaccine.',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 2.5;
  margin-right: 30px;
  padding: 0 1rem;
  @media (max-width: 1024px) {
    margin-right: 5px;
    padding: 0 0.5rem;
  }
  @media screen and (max-width: 1290px) {
    margin-right: initial;
    font-size: 16px;
  }
  /* color: #28a84a; */
}
h4 a {
  text-decoration: none;
  color: black;
}
button {
  padding: 20px 2rem !important;
  background: #28a84a !important;
  border: 2px solid #28a84a;
  box-sizing: border-box !important;
  border-radius: 5px;
  font-size: 12px;
  color: white !important;
}
.router-link-active {
  color: #28a84a;
}
.v-list-item:hover {
  background: rgba(162, 183, 190, 0.46);
  cursor: pointer;
}
.v-menu__content {
  margin-top: 12px;
  border-radius: 0;
  padding: auto;
  left: 984px;
  z-index: 100 !important;
}
.contact {
  // left: 933px !important;
  z-index: 100 !important;
}
.v-list {
  padding: initial;
}
.last {
  @media (max-width: 1024px) {
    margin-right: 35px;
  }

  @media (max-width: 1290px) {
    margin-right: 50px;
  }
}
button.v-app-bar__nav-icon {
  padding: 0 25px !important;
  background: #28a84a !important;
  border: 2px solid #28a84a;
  box-sizing: border-box !important;
  border-radius: 5px;
  font-size: 12px;
  color: white !important;
  height: 40px !important;
}

.v-list .v-list-item--active {
  color: #28a84a !important;
}

// .v-list-item-link
//   .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
//   color: red !important;
// }
// .test {
//   border: 2px dashed orange !important;
// }
// .icon-class {
//   position: relative;
//   right: 3.5rem;
// }
.set-active {
  a {
    color: #28a84a !important;
  }
}
.logo {
  width: 8rem;
}
.mobile {
  padding: 1rem;
  .mobile-logo {
    width: 5rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }
}
</style>
