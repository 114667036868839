<template>
  <v-dialog v-model="dialog" width="fit-content" persistent>
    <div class="relative dialog-container">
      <v-icon
        medium
        color="black darken-2"
        class="icon"
        @click="dialog = false"
      >
        mdi-close
      </v-icon>
      <div>
        <v-img
          src="../assets/special-offer/Group 4416.png"
          class="gift-img"
        ></v-img>
        <p class="offer mb-0">SPECIAL OFFER</p>
        <p class="discount">20% OFF</p>
        <p class="new mb-0">New to Gricd?</p>
        <p class="claim">Claim your new user discounts</p>

        <div class="d-flex justify-center">
          <v-btn @click="handleGetIt" class="btn">GET IT</v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "SpecialOfferModal",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    handleGetIt() {
      this.$emit("open-ofer-form");
      this.dialog = false;
    },
    openSpecialOfferTimer() {
      setTimeout(() => {
        this.dialog = true;
      }, 25000);
    },
  },
  mounted() {
    this.openSpecialOfferTimer();
  },
};
</script>

<style lang="scss" scoped>
.gift-img {
  width: 120px;
  margin: auto;
  margin-bottom: 15px;
}
.dialog-container {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 50px;
  position: relative;
}
.btn {
  background: #28a84a !important;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
  height: 30px;
}
p {
  text-align: center;
  margin-bottom: 5px;
}
.offer {
  font-weight: 400;
  font-size: 21px;
  line-height: 0.7;
}
.discount {
  font-weight: 900;
  font-size: 38.0874px;
  line-height: 45px;
  color: #28a84a;
}
.new,
.claim {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}
.new {
  line-height: 0.8;
}
.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  color: #606060;
}
</style>
